import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

import about_img_copy from '../assets/images/about_img_copy.jpg'

const Generic = props => (
  <Layout>
    <Helmet>
      <title>About</title>
      <meta name="description" content="Generic Page" />
    </Helmet>

    <div id="main" className="alt">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h1>About</h1>
          </header>
          <span className="image main">
            <img src={about_img_copy} alt="vector woman img" />
          </span>
          <p>
            Hi! I am Katiuscia Novaes,  I am enthusiastic about web development
            &amp; design. I love programming, traveling, paddle boarding, and
            coffee. A passionate Web Developer having experience in building Web
            applications and E-commerce store, using CMS platforms or many
            programming languages, as Javascript/ Reactjs / Nodejs / PHP. I have
            designed websites, and many products page in e-commerce stores,
            creating attractive images, GIFs, and making proper use of videos in
            order to enhance products' attractiveness. Creating new online
            business models, developing and implementing digital and e-commerce
            marketing strategies to optimize user experience and SEO. 
          </p>
          <p>
            I'm proficient in many programming languages (HTML, CSS,
            PHP, JS and others), design (Gravity, Canva, Inkscape and others),
            database, email, and CMS softwares. With strong experience in designing
            and creating e-commerce stores, keeping the website accessibility
            friendly and maximizing user experience and SEO. 
            I can help you re-design or stores and start a new one!
            With experience in many web platforms and content management systems (as Shopify,
            Squareup, Squarespace, Wix, Wordpress and others) to marketing and social media 
            strategies, making use of analytical tools to improve brand awareness, increase sales, 
            and create reports, and ads.
          </p>
          <p>
            Designing and Coding have been my passion since the days I started my
            Programming Degree in 2017. I enjoy creating modern designed,
            intuitive, and functional websites. </p>
            
            <p>If you think I might help you, I would love to hear from you!</p>
            <p>Feel free to contact me!    </p>

        </div>
      </section>
    </div>
  </Layout>
)

export default Generic
